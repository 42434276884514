import { FC, PropsWithChildren, createContext, useMemo, useRef } from "react";
import { GridConfigCache } from "../types";
import GridConfigManager from "./GridConfigCacheManager/GridConfigCacheManager";
import { defaultCache } from "./utils";

export const GenericGridConfigurationContext = createContext<{ configManager: GridConfigManager | undefined }>({ configManager: undefined });

const GenericGridConfigurationManager: FC<PropsWithChildren> = ({ children }) => {
    const cache = useRef<GridConfigCache>(defaultCache);
    const initializedManager = useRef<GridConfigManager>(new GridConfigManager(cache));

    const providerValue = useMemo(
        () => ({
            configManager: initializedManager.current,
        }),
        [initializedManager.current],
    );

    return <GenericGridConfigurationContext.Provider value={providerValue}>{children}</GenericGridConfigurationContext.Provider>;
};

export default GenericGridConfigurationManager;
