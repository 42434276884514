import { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createTheme as createMuiTheme, ThemeProvider } from "@mui/material/styles";
import ReactGA from "react-ga4";
import ActionSpinner from "./config/axios/ActionSpinner/ActionSpinner";
import CircularProgressSpinner from "./config/axios/CircularProgressbar/CircularProgressSpinner";
import pic from "./appBackgrounds/1HFE-Background.jpg";
import { defaultThemeConfig, generateTheme } from "./utils/muiUtils";
import AppRoute from "./route/AppRoute";
import { initObject } from "./config/analytics/analytics";
import useAsyncError from "./Hooks/useAsyncError";
import { DEFAULT_TENANT_ID, NODE_ENV } from "./config/app.config";
import { BO_CORE_DEFAULT_VALUE, isCancelledErrorProcessor } from "./utils/AppUtils";
import SuspenseSpinner from "./components/Common/SuspenseSpinner/SuspenseSpinner";
import "./utils/modules";
import "react-toastify/dist/ReactToastify.css";
import useAbortApi from "./Hooks/useAbortApi";
import { uiActions } from "./reducers/uiReducer";
import { initialize } from "./config/axios/Axios";
import GenericGridConfigurationManager from "./contexts/GenericGridConfigurationContext/GenericGridConfigurationContext";
import { getTenantConfig } from "./config/axios/apis/tenant/tenant";
import { isAccessTokenPresent, isRefreshTokenPresent } from "./utils/AuthUtils/AuthUtils";

initialize();

const App = () => {
    const dispatch = useDispatch();
    const [customTheme, setCustomTheme] = useState<any>(null);
    // const isAuthenticated = AuthUtils.isAccessTokenPresent();
    // const user = useSelector((store: any) => store.userReducer.user);
    // const locale = useSelector((store: any) => store.userReducer.locale);
    const throwError = useAsyncError();
    const { signal } = useAbortApi();
    const isAuthenticated = isAccessTokenPresent() || isRefreshTokenPresent();

    let link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
    }

    // useEffect(() => {
    //     const controller = new AbortController()
    //     if (isAuthenticated && !user) {
    //         console.log("app");

    //         dispatch(fetchAuthUser());
    //         AuthUtils.mySelfRequest({ locale }, signal);
    //         // todo attach signal here
    //     }
    //     return () => controller.abort()
    // }, [user?.tenantContext]);

    const createTheme = (themeConfig: any) => {
        const theme = generateTheme(themeConfig);
        setCustomTheme(theme);
    };

    useEffect(() => {
        if (process.env.NODE_ENV !== "development" && !isAuthenticated) {
            // Delay the GA initialization because in some cases we need time to disable it.
            // Disabled for authenticated users because of HIPAA requirements.
            // For example when we hit Authorized page GA should be disabled.
            // Use window[`ga-disable-${GOOGLE_MEASUREMENT_ID}`] with value true to disable GA.
            setTimeout(() => {
                ReactGA.initialize(initObject);
            }, 1000);
        }

        getTenantConfig({ signal })
            .then((response) => {
                createTheme(response.data.themeConfiguration.muiTheme);
                if (response.data.publicUrlTitleIcon !== "" && response.data.publicUrlTitleIcon !== BO_CORE_DEFAULT_VALUE) {
                    link.href = response.data.publicUrlTitleIcon;
                }
                // dispatch({ type: "setTenantId", tenantId: response.data.tenant });

                if (NODE_ENV === "development") {
                    dispatch(uiActions.setGooglePlacesApiKey("AIzaSyClEvyBCwThKl86W3BZCaouyIBp8rqDoVE"));
                } else {
                    dispatch(uiActions.setGooglePlacesApiKey(response.data.googlePlacesApiKey));
                }

                if (response.data.tenantName && response.data.tenantId !== DEFAULT_TENANT_ID) {
                    dispatch(uiActions.setPublicTenantData(response.data));
                }
                // if (response.data.tenantName && response.data.tenantName !== "Default") {
                //     // !! this pollutes the state. the responses of  /api/v2/tenant and /api/v2/public/tenant/config are very different, but SET_TENANT action is used in both cases. Dirty fix below in order to get NG-1484 working correctly
                //     dispatch({ type: actions.SET_TENANT, payload: { id: response.data.tenantId, ...response.data } });
                // }

                dispatch(
                    uiActions.setThemeProps({
                        data: {
                            mainBackgroundImage: response.data.publicUrlBackground !== BO_CORE_DEFAULT_VALUE ? response.data.publicUrlBackground : "",
                            loginBackgroundImage: response.data.publicUrlLoginImage !== BO_CORE_DEFAULT_VALUE ? response.data.publicUrlLoginImage : "",
                            logoImage: response.data.publicUrlLogo !== BO_CORE_DEFAULT_VALUE ? response.data.publicUrlLogo : "",
                            titleIcon: response.data.publicUrlTitleIcon !== BO_CORE_DEFAULT_VALUE ? response.data.publicUrlTitleIcon : "",
                        },
                        appBackgroundStyle: {
                            backgroundImage: response.data.publicUrlBackground !== BO_CORE_DEFAULT_VALUE ? `url(${response.data.publicUrlBackground})` : "",
                            backgroundColor:
                                response.data.themeConfiguration.muiTheme.palette.type !== BO_CORE_DEFAULT_VALUE
                                    ? response.data.themeConfiguration.muiTheme.palette.type
                                    : "#EAEAF2",
                        },
                        publicBackgroundStyle: {
                            backgroundImage:
                                response.data.publicUrlLoginImage !== BO_CORE_DEFAULT_VALUE ? `url(${response.data.publicUrlLoginImage})` : `url(${pic})`,
                            backgroundColor:
                                response.data.themeConfiguration.muiTheme.palette.type !== BO_CORE_DEFAULT_VALUE
                                    ? response.data.themeConfiguration.muiTheme.palette.type
                                    : "#EAEAF2",
                        },
                        publicUrlLogo: response.data.publicUrlLogo !== BO_CORE_DEFAULT_VALUE ? response.data.publicUrlLogo : "",
                        messageBackground: response.data.themeConfiguration.customSettings.messageBackground,
                    }),
                );
            })
            .catch(isCancelledErrorProcessor)
            .catch(throwError);
    }, [signal]);

    if (customTheme === null) {
        return null;
    }

    return (
        <ThemeProvider theme={createMuiTheme(defaultThemeConfig)}>
            <ToastContainer />
            <Suspense fallback={<SuspenseSpinner />}>
                <ActionSpinner />
                <CircularProgressSpinner />
                <GenericGridConfigurationManager>
                    <AppRoute />
                </GenericGridConfigurationManager>
            </Suspense>
        </ThemeProvider>
    );
};

export default App;
