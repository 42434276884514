import { FC, useState } from "react";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, TextField, useMediaQuery } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import ReCAPTCHA from "react-google-recaptcha";
import { schema, defaultValues } from "./OrgnizationCreateFromUtils";
import { OrganizationForm } from "../../types";
import { boxStyle } from "../../../../../AppGlobalStyles";
import { RECAPTCHA_SITE_KEY } from "../../../../../config/app.config";
import GoogleAutocompleteAddress from "../../../../../components/AddressComponent/SingleAddress/components/GoogleAutocompleteAddress/GoogleAutocompleteAddress";
import { AppAddressCountries, CreateOrganizationKey } from "../../../../../utils/appEnums/AppEnums";
import {
    checkNewGoogleAddressIsVerified,
    defaultAddressLocation,
    getAddressValuesFromPlacesApi,
} from "../../../../../components/AddressComponent/SingleAddress/components/GoogleAutocompleteAddress/GoogleAutocompleteAddressUtils";
import { StyledAdminInfo, StyledBoxWrapper, StyledOrgInfo } from "../../styles/styles";
import { BO_CORE_DEFAULT_VALUE, success, toastError } from "../../../../../utils/AppUtils";
import { isHttpErrorMessageAvailable } from "../../../../../config/axios/Axios";
import { AddressLocation } from "../../../../../components/AddressComponent/SingleAddress/components/GoogleAutocompleteAddress/GoogleAutocompleteAddressTypes";
import { createHealthcareProvider as createOrganization } from "../../../../../config/axios/apis/organization/organization";

interface OrganizationCreateFromProps {
    createOrg: (value: boolean) => void;
}

const OrganizationCreateFrom: FC<OrganizationCreateFromProps> = ({ createOrg }) => {
    const mobile = useMediaQuery("(min-width:500px)");
    const params = useSearchParams()[0];
    const { formatMessage } = useIntl();
    const [organizationAddress, setOrganizationAddress] = useState<AddressLocation>(defaultAddressLocation);
    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        watch,
        formState: { errors },
    } = useForm<OrganizationForm>({
        resolver: yupResolver(schema),
        mode: "onTouched",
        reValidateMode: "onBlur",
        criteriaMode: "all",
        defaultValues,
    });
    const token = watch("recaptchaToken");

    const handleCreateOrganization = (data: OrganizationForm) => {
        const apiDTO = {
            createTenantRequest: {
                name: data.name,
                headquartersAddress: {
                    addressLine1: organizationAddress.addressLine1,
                    addressLine2: organizationAddress.addressLine2,
                    cityName: organizationAddress.city,
                    country: organizationAddress.country,
                    countryCode: BO_CORE_DEFAULT_VALUE,
                    county: organizationAddress.county,
                    lat: organizationAddress.lat,
                    lon: organizationAddress.lon,
                    name: `${getValues("name")}-(${uuidv4()})`,
                    postalCode: organizationAddress.zipCode,
                    stateName: organizationAddress.state,
                    type: organizationAddress.type,
                    validated: organizationAddress.isVerified,
                    fullAddress: organizationAddress.fullAddress,
                },
            },
            productCode: params.get("product"),
            admin: {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
            },
        };

        createOrganization(apiDTO, CreateOrganizationKey.LAB_SUPPLIER_GTM, { withCredentials: false })
            .then((res) => {
                if (res?.data) {
                    success(formatMessage({ id: "create-organization.successfulCreation", defaultMessage: "Successfully created organization!" }));
                    createOrg(true);
                }
            })
            .catch((error: any) => {
                createOrg(false);
                if (!isHttpErrorMessageAvailable(error)) {
                    toastError(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
                }
            });
    };
    return (
        <StyledBoxWrapper>
            <StyledOrgInfo variant="h2">
                <FormattedMessage id="non-logged.create-organization-title-info" defaultMessage="Organization Information" />
            </StyledOrgInfo>
            <Box sx={boxStyle}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => (
                        <TextField
                            {...field}
                            id="non-logged-org-name"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            label={formatMessage({ id: "non-logged.create-organization-label-name", defaultMessage: "Organization name" })}
                            placeholder={formatMessage({ id: "non-logged.create-organization-label-name", defaultMessage: "Organization name" })}
                            error={invalid}
                            helperText={invalid ? formatMessage({ id: `error.create-organization-name-${error!.type}`, defaultMessage: error!.message }) : ""}
                        />
                    )}
                />
            </Box>
            <Box sx={{ ...boxStyle, marginTop: ".8rem" }}>
                <GoogleAutocompleteAddress
                    country={AppAddressCountries.UNITED_STATES_OF_AMERICA}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    setAddressValuesFromPlacesApi={(addressItem) => {
                        const { location: mutatedLocation } = getAddressValuesFromPlacesApi(defaultAddressLocation, addressItem);
                        mutatedLocation.type = "Headquarters";
                        mutatedLocation.country = AppAddressCountries.UNITED_STATES_OF_AMERICA;
                        mutatedLocation.isVerified = checkNewGoogleAddressIsVerified(mutatedLocation, addressItem);
                        setValue("address", mutatedLocation.fullAddress);
                        setOrganizationAddress({
                            id: -1,
                            addressLine1: mutatedLocation.addressLine1,
                            addressLine2: mutatedLocation.addressLine2,
                            city: mutatedLocation.city,
                            country: mutatedLocation.country,
                            county: mutatedLocation.county,
                            fullAddress: mutatedLocation.fullAddress,
                            lat: mutatedLocation.lat,
                            lon: mutatedLocation.lon,
                            zipCode: mutatedLocation.zipCode,
                            state: mutatedLocation.state,
                            type: mutatedLocation.type,
                            isVerified: mutatedLocation.isVerified,
                        });
                    }}
                    onAddressChange={() => setOrganizationAddress(defaultAddressLocation)}
                    fieldLabel={formatMessage({ id: "non-logged.create-organization-label-address", defaultMessage: "Organization Address" })}
                    screenName="error.create-organization"
                    required
                />
            </Box>
            <StyledAdminInfo variant="h2">
                <FormattedMessage id="non-logged.create-organization-label-sys-admin" defaultMessage=" System Administrator" />
            </StyledAdminInfo>
            <Box sx={{ ...boxStyle, marginTop: ".8rem", alignItems: "flex-start" }}>
                <Controller
                    name="firstName"
                    control={control}
                    render={({ field, fieldState: { invalid } }) => (
                        <TextField
                            {...field}
                            id="non-logged-firstname"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 24 }}
                            label={formatMessage({ id: "non-logged.create-organization-label-firstName", defaultMessage: "First name" })}
                            placeholder={formatMessage({ id: "non-logged.create-organization-label-firstName", defaultMessage: "First name" })}
                            error={invalid}
                            helperText={
                                invalid ? (
                                    <FormattedMessage
                                        id="non-logged.create-organization-error-firstname-required"
                                        defaultMessage="First name is a required field"
                                    />
                                ) : (
                                    ""
                                )
                            }
                        />
                    )}
                />
                <Controller
                    name="lastName"
                    control={control}
                    render={({ field, fieldState: { invalid } }) => (
                        <TextField
                            {...field}
                            id="non-logged-lastname"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            inputProps={{ maxLength: 24 }}
                            required
                            label={formatMessage({ id: "non-logged.create-organization-label-lastName", defaultMessage: "Last name" })}
                            placeholder={formatMessage({ id: "non-logged.create-organization-label-lastName", defaultMessage: "Last name" })}
                            error={invalid}
                            helperText={
                                errors.lastName ? (
                                    <FormattedMessage
                                        id="non-logged.create-organization-error-lastname-required"
                                        defaultMessage="Last name is a required field"
                                    />
                                ) : (
                                    ""
                                )
                            }
                        />
                    )}
                />
            </Box>
            <FormControl>
                <Box sx={{ ...boxStyle, marginTop: ".8rem" }}>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                                {...field}
                                id="non-logged-email"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                required
                                label={formatMessage({ id: "non-logged.create-organization-label-email", defaultMessage: "Email" })}
                                placeholder={formatMessage({ id: "non-logged.create-organization-label-email", defaultMessage: "Email" })}
                                error={invalid}
                                helperText={
                                    invalid ? (
                                        <FormattedMessage id={`non-logged.create-organization-error-email-${error!.type}`} defaultMessage={error!.message} />
                                    ) : (
                                        ""
                                    )
                                }
                            />
                        )}
                    />
                </Box>
                <Box sx={{ boxStyle }}>
                    <FormHelperText style={{ padding: "1rem .5rem", textAlign: "left", color: "rgba(40, 40, 40, 0.87)", fontSize: ".875rem" }}>
                        <FormattedMessage
                            id="non-logged.create-organization.email-info-message"
                            defaultMessage=" The System Administrator will receive a verification email to set their password after the Continue button is clicked. This is the person who will initially control your organization’s 1health account."
                        />
                    </FormHelperText>
                </Box>
            </FormControl>

            <Box sx={boxStyle}>
                <Controller
                    control={control}
                    name="permission"
                    render={({ field: { value, onChange, ...field }, fieldState: { invalid } }) => (
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={value}
                                        onChange={onChange}
                                        sx={{
                                            color: (theme) => (invalid ? `${theme.palette.error.main} !important` : `${theme.palette.silver?.main} !important`),
                                            "&.Mui-checked": { color: (theme) => `${theme.palette.softBlack?.hover} !important` },
                                        }}
                                    />
                                }
                                slotProps={{
                                    typography: {
                                        color: (theme) => (invalid ? `${theme.palette.error.main} !important` : `${theme.palette.softBlack?.main} !important`),
                                    },
                                }}
                                label={
                                    <FormattedMessage
                                        id="non-logged.create-organization-permission"
                                        defaultMessage="I have permissive authority to represent the above organization*"
                                    />
                                }
                            />
                            <FormHelperText error={invalid}>
                                {invalid && (
                                    <FormattedMessage
                                        id="non-logged.create-organization-permission.error.confirm-authorized-to-represent"
                                        defaultMessage="Please confirm that you have permissive authority to represent the above organization"
                                    />
                                )}
                            </FormHelperText>
                        </FormControl>
                    )}
                />
            </Box>
            <Box sx={boxStyle}>
                <Controller
                    control={control}
                    name="recaptchaToken"
                    render={({ field: { onChange } }) => (
                        <ReCAPTCHA
                            key={mobile.toString()}
                            sitekey={RECAPTCHA_SITE_KEY}
                            onChange={(revisedToken) => onChange(revisedToken)}
                            onExpired={() => onChange("")}
                            onErrored={() => onChange("")}
                            size={mobile ? "normal" : "compact"}
                        />
                    )}
                />
            </Box>
            <Box sx={{ paddingBottom: "2rem" }}>
                <Button
                    variant="CTA"
                    sx={{
                        minWidth: "350px",
                        marginTop: "2rem",
                        backgroundColor: "#FBC84A !important",
                        color: "rgba(40, 40, 40, 0.87) !important",
                        fontWeight: "600 !important",
                        "&:hover": { color: "rgba(40, 40, 40, 0.87) !important" },
                    }}
                    onClick={handleSubmit(handleCreateOrganization)}
                    disabled={!token}
                >
                    <FormattedMessage id="non-logged.create-organization-btn-label" defaultMessage="Continue" />
                </Button>
            </Box>
        </StyledBoxWrapper>
    );
};

export default OrganizationCreateFrom;
