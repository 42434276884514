import { AxiosResponse } from "axios";
import { getAxiosInstances } from "../../../../utils/AppUtils";
import AxiosActions from "../../AxiosActions";
import { getAccessToken } from "../../../../utils/AuthUtils/AuthUtils";

export const getUserMetadata = (config): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, "tenant", { ...config }, false);
};

export const getUserTenant = () => {
    return AxiosActions.get(getAxiosInstances().V2, "tenant", {}, true);
};
// not currently used 21.06.2022 leaving it for debug purposes
export const requestJoinTenant = (id: number) => {
    return AxiosActions.post(getAxiosInstances().V2, `/tenant/${id}/join`, {}, {}, true);
};

export const updateTenantData = (dto: any) => {
    return AxiosActions.put(getAxiosInstances().V2, `/tenant`, dto, {}, true);
};

export const getTenantSysConfig = (ids: number[], config = {}, spinner = true) => {
    return AxiosActions.get(
        getAxiosInstances().V2,
        `/tenant/sys-config?${ids.map((id) => `Tenant%20IDs=${id}`).join("&")}&includeAllRelations=true`,
        { ...config },
        spinner,
    );
};
export const getTenantConfig = (config): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/public/tenant/config`, { ...config, withCredentials: false }, false);
};
export const createOrganizationWithFiles = async (
    dto,
    lightThemedIcon?: File,
    lightThemedLogo?: File,
    darkThemedIcon?: File,
    darkThemedLogo?: File,
): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append("dto", JSON.stringify(dto));
    if (lightThemedIcon) {
        formData.append("lightThemedIcon", lightThemedIcon);
    }
    if (lightThemedLogo) {
        formData.append("lightThemedLogo", lightThemedLogo);
    }
    if (darkThemedIcon) {
        formData.append("darkThemedIcon", darkThemedIcon);
    }
    if (darkThemedLogo) {
        formData.append("darkThemedLogo", darkThemedLogo);
    }
    return AxiosActions.post(getAxiosInstances().V2, `tenant`, formData, {}, true);
};
// switch tenant

// We are using serverSwitchTenantContext instead
// Internally BoCore (/api/v2/user/switch-tenant/{id}) will call the auth service (/auth/tenant/switch-tenant?tenantId=${tenantId}&userId=${userId})
// export const switchTenantContext = (tenantId: number): Promise<AxiosResponse> => {
//     return AxiosActions.get(getAxiosInstances().V2, `user/switch-tenant/${tenantId}`, {}, false, false);
// };

export const serverSwitchTenantContext = (tenantId: number, userId: number, token): Promise<AxiosResponse> => {
    return AxiosActions.post(
        getAxiosInstances().AUTH,
        `tenant/switch-tenant?tenantId=${tenantId}&userId=${userId}`,
        { token },
        {
            // ...config,
            headers: { Authorization: `Bearer ${getAccessToken()}` },
        },
        false,
    );
};
