export enum HEALTHCARE_SUB_ROUTE {
    // BASE = "/:orgType",
    BASE = "/healthcare",
    HEALTHCARE_PLACE_ORDER = "place-order",
    HEALTHCARE_ORDERS = "orders",
    HEALTHCARE_ORDER = "order",
    HEALTHCARE_PATIENT = "patients",
    HEALTHCARE_MEDICAL_DATABASE = "medical-database",
}

const LAB_REQUISITION_ROUTE = "requisition";
const TEST_CATALOG_ROUTE = "test-catalog";
const DRY_LAB_TEST_CATALOG_ROUTE = "dry-lab-test-catalog";

export enum LAB_SUB_ROUTE {
    // BASE = "/:orgType",
    BASE = "/lab",
    TEST_CATALOG = `${TEST_CATALOG_ROUTE}/list`,
    LAB_REQUISITION = `${LAB_REQUISITION_ROUTE}/list`,
    LAB_REQUISITION_DETAILS = `${LAB_REQUISITION_ROUTE}/details`,
    TEST_OFFERED_CREATE = `${TEST_CATALOG_ROUTE}/create`,
    TEST_OFFERED_EDIT = `${TEST_CATALOG_ROUTE}/edit`,
    DRY_LAB_TEST_CATALOG = `${DRY_LAB_TEST_CATALOG_ROUTE}/list`,
    DRY_LAB_TEST_OFFERED_CREATE = `${DRY_LAB_TEST_CATALOG_ROUTE}/create`,
    DRY_LAB_TEST_OFFERED_EDIT = `${DRY_LAB_TEST_CATALOG_ROUTE}/edit`,
    LAB_PATIENTS = "patients",
    LAB_REQUISITION_ORDER = "order",
}

const SAMPLING_KIT_ROUTE = "sampling-kit";
const SUPPLY_CATALOG_ROUTE = "supply-catalog";

export enum SUPPLY_SUB_ROUTE {
    BASE = "/supply",
    SAMPLING_KIT = `${SAMPLING_KIT_ROUTE}/list`,
    SAMPLING_KIT_CREATE = `${SAMPLING_KIT_ROUTE}/create`,
    SAMPLING_KIT_EDIT = `${SAMPLING_KIT_ROUTE}/edit`,
    SUPPLY_CATALOG = `${SUPPLY_CATALOG_ROUTE}/list`,
    SUPPLY_CATALOG_CREATE = `${SUPPLY_CATALOG_ROUTE}/create`,
    SUPPLY_CATALOG_EDIT = `${SUPPLY_CATALOG_ROUTE}/edit`,
    SUPPLY_FULFILLMENTS_ORDERS = "fulfillment-orders",
}

const TEST_PRODUCT_ROUTE = "test-product";

export enum GO_TO_MARKET_SUB_ROUTE {
    BASE = "/go-to-market",
    GO_TO_MARKET_PLACE_ORDER = "place-order",
    GO_TO_MARKET_ORDERS = "orders",
    GO_TO_MARKET_ORDER = "order",
    GO_TO_MARKET_WORKFLOW_TEMPLATES = "application-templates",
    GO_TO_MARKET_AI_BUILDER = "application-templates/ai-builder",
    TEST_PRODUCT = `${TEST_PRODUCT_ROUTE}/list`,
    TEST_PRODUCT_CREATE = `${TEST_PRODUCT_ROUTE}/create`,
    TEST_PRODUCT_EDIT = `${TEST_PRODUCT_ROUTE}/edit`,
    GO_TO_MARKET_PRODUCT_GROUPS = "product-groups",
    GO_TO_MARKET_WORKFLOW_TEMPLATES_PUBLIC = "application-templates/public",
}

const ACADEMIC_TEST_ROUTE = "academic-test";
const ACADEMIC_DISEASE_ROUTE = "academic-disease";

export enum MEDICAL_DATABASE_SUB_ROUTE {
    MEDICAL_DATABASE_SUB_ROUTE_BASE = "/medical-database",
    ACADEMIC_TEST = `${ACADEMIC_TEST_ROUTE}/:id`,
    ACADEMIC_DISEASE = `${ACADEMIC_DISEASE_ROUTE}/:id`,
}

const PATIENT_ROOT_ROUTE = "patient";
const CREATE_REQUISITION_ROUTE = "create-requisition";

export enum PATIENT_DETAILS_SUB_ROUTE {
    PATIENT_DETAILS_ROUTE = `${PATIENT_ROOT_ROUTE}/:personId/details`,
    PATIENT_CREATE_REQUISITION = `${PATIENT_ROOT_ROUTE}/:personId/${CREATE_REQUISITION_ROUTE}`,
}

const PRODUCT_ROUTE = "product";

export enum PRODUCT {
    TEST_PRODUCT_DETAILS_ROUTE = `${PRODUCT_ROUTE}/:testProductId/details`,
}

enum CAMPAIGN_SUB_ROUTE {
    CAMPAIGN_ROUTE_BASE = "/campaign",
    CAMPAIGN_LIST = "list",
    CAMPAIGN_DETAILS = ":campaignId/details",
}

export enum Routes {
    BASE_PATH = "/",
    HOME = "/home",
    TENANT_HOME_PAGE = "/tenant-homepage",
    IMG = "/img",
    WELCOME = "/welcome",
    PROFILE = "/profile",
    MY_TESTS = "/my-tests",
    PERSONAL_TEST_RESULTS_LIST = "/my-orders",
    PERSONAL_PLACE_ORDER = "/place-order",
    PERSONAL_MEDICAL_RECORDS = "/medical-records",
    REGISTER_NEW_KIT = "/register-new-kit",
    PERSONAL_TEST_RESULTS_VIEW = "/my-tests/results/view",
    TEST_RESULTS_VIEW = "/test-results/view",
    TEST_RESULTS_VIEW_OLD = "/test-results/old/view", // /my-tests/results/view ???
    HEALTHCARE_PLACE_ORDER = `${HEALTHCARE_SUB_ROUTE.BASE}/${HEALTHCARE_SUB_ROUTE.HEALTHCARE_PLACE_ORDER}`,
    HEALTHCARE_ORDERS = `${HEALTHCARE_SUB_ROUTE.BASE}/${HEALTHCARE_SUB_ROUTE.HEALTHCARE_ORDERS}`,
    HEALTHCARE_ORDER = `${HEALTHCARE_SUB_ROUTE.BASE}/${HEALTHCARE_SUB_ROUTE.HEALTHCARE_ORDER}`,
    HEALTHCARE_PATIENT = `${HEALTHCARE_SUB_ROUTE.BASE}/${HEALTHCARE_SUB_ROUTE.HEALTHCARE_PATIENT}`,
    // ACCOUNT_SETTINGS = "/account-settings",
    PERSONAL_SETTINGS = "/personal-settings",

    TENANT_SETTINGS = "/tenant/settings",
    APPLICATIONS = "/applications",
    TEST_CATALOG = `${LAB_SUB_ROUTE.BASE}/${LAB_SUB_ROUTE.TEST_CATALOG}`,
    LAB_LAB_REQUISITION = `${LAB_SUB_ROUTE.BASE}/${LAB_REQUISITION_ROUTE}`,
    LAB_REQUISITION = `${LAB_SUB_ROUTE.BASE}/${LAB_SUB_ROUTE.LAB_REQUISITION}`,
    LAB_LAB_REQUISITION_ORDER = `${LAB_SUB_ROUTE.BASE}/${LAB_SUB_ROUTE.LAB_REQUISITION}/${LAB_SUB_ROUTE.LAB_REQUISITION_ORDER}`,
    LAB_REQUISITION_DETAILS = `${LAB_SUB_ROUTE.BASE}/${LAB_SUB_ROUTE.LAB_REQUISITION_DETAILS}`,
    LAB_TEST_CATALOG = `${LAB_SUB_ROUTE.BASE}/${LAB_SUB_ROUTE.TEST_CATALOG}`,
    TEST_OFFERED_CREATE = `${LAB_SUB_ROUTE.BASE}/${LAB_SUB_ROUTE.TEST_OFFERED_CREATE}`,
    TEST_OFFERED_EDIT = `${LAB_SUB_ROUTE.BASE}/${LAB_SUB_ROUTE.TEST_OFFERED_EDIT}`,
    DRY_TEST_CATALOG = `${LAB_SUB_ROUTE.BASE}/${DRY_LAB_TEST_CATALOG_ROUTE}`,
    DRY_LAB_TEST_CATALOG = `${LAB_SUB_ROUTE.BASE}/${LAB_SUB_ROUTE.DRY_LAB_TEST_CATALOG}`,
    DRY_LAB_TEST_OFFERED_CREATE = `${LAB_SUB_ROUTE.BASE}/${LAB_SUB_ROUTE.DRY_LAB_TEST_OFFERED_CREATE}`,
    DRY_LAB_TEST_OFFERED_EDIT = `${LAB_SUB_ROUTE.BASE}/${LAB_SUB_ROUTE.DRY_LAB_TEST_OFFERED_EDIT}`,
    LAB_PATIENTS = `${LAB_SUB_ROUTE.BASE}/${LAB_SUB_ROUTE.LAB_PATIENTS}`,
    SAMPLING_KIT = `${SUPPLY_SUB_ROUTE.BASE}/${SUPPLY_SUB_ROUTE.SAMPLING_KIT}`,
    SAMPLING_KIT_CREATE = `${SUPPLY_SUB_ROUTE.BASE}/${SUPPLY_SUB_ROUTE.SAMPLING_KIT_CREATE}`,
    SAMPLING_KIT_EDIT = `${SUPPLY_SUB_ROUTE.BASE}/${SUPPLY_SUB_ROUTE.SAMPLING_KIT_EDIT}`,
    SUPPLY_SAMPLING_KIT = `${SUPPLY_SUB_ROUTE.BASE}/${SAMPLING_KIT_ROUTE}`,
    SUPPLY_SUPPLY_CATALOG = `${SUPPLY_SUB_ROUTE.BASE}/${SUPPLY_CATALOG_ROUTE}`,
    SUPPLY_CATALOG = `${SUPPLY_SUB_ROUTE.BASE}/${SUPPLY_SUB_ROUTE.SUPPLY_CATALOG}`,
    SUPPLY_CATALOG_CREATE = `${SUPPLY_SUB_ROUTE.BASE}/${SUPPLY_SUB_ROUTE.SUPPLY_CATALOG_CREATE}`,
    SUPPLY_CATALOG_EDIT = `${SUPPLY_SUB_ROUTE.BASE}/${SUPPLY_SUB_ROUTE.SUPPLY_CATALOG_EDIT}`,
    SUPPLY_FULFILLMENTS_ORDERS = `${SUPPLY_SUB_ROUTE.BASE}/${SUPPLY_SUB_ROUTE.SUPPLY_FULFILLMENTS_ORDERS}`,
    GO_TO_MARKET_PLACE_ORDER = `${GO_TO_MARKET_SUB_ROUTE.BASE}/${GO_TO_MARKET_SUB_ROUTE.GO_TO_MARKET_PLACE_ORDER}`,
    GO_TO_MARKET_ORDERS = `${GO_TO_MARKET_SUB_ROUTE.BASE}/${GO_TO_MARKET_SUB_ROUTE.GO_TO_MARKET_ORDERS}`,
    GO_TO_MARKET_ORDER = `${GO_TO_MARKET_SUB_ROUTE.BASE}/${GO_TO_MARKET_SUB_ROUTE.GO_TO_MARKET_ORDER}`,
    GO_TO_MARKET_TEST_PRODUCT = `${GO_TO_MARKET_SUB_ROUTE.BASE}/${TEST_PRODUCT_ROUTE}`,
    GO_TO_MARKET_WORKFLOW_TEMPLATES = `${GO_TO_MARKET_SUB_ROUTE.BASE}/${GO_TO_MARKET_SUB_ROUTE.GO_TO_MARKET_WORKFLOW_TEMPLATES}`,
    GO_TO_MARKET_WORKFLOW_TEMPLATES_PUBLIC = `${GO_TO_MARKET_SUB_ROUTE.BASE}/${GO_TO_MARKET_SUB_ROUTE.GO_TO_MARKET_WORKFLOW_TEMPLATES_PUBLIC}`,
    GO_TO_MARKET_AI_BUILDER = `${GO_TO_MARKET_SUB_ROUTE.BASE}/${GO_TO_MARKET_SUB_ROUTE.GO_TO_MARKET_AI_BUILDER}`,
    TEST_PRODUCT = `${GO_TO_MARKET_SUB_ROUTE.BASE}/${GO_TO_MARKET_SUB_ROUTE.TEST_PRODUCT}`,
    TEST_PRODUCT_CREATE = `${GO_TO_MARKET_SUB_ROUTE.BASE}/${GO_TO_MARKET_SUB_ROUTE.TEST_PRODUCT_CREATE}`,
    TEST_PRODUCT_EDIT = `${GO_TO_MARKET_SUB_ROUTE.BASE}/${GO_TO_MARKET_SUB_ROUTE.TEST_PRODUCT_EDIT}`,
    GO_TO_MARKET_PRODUCT_GROUPS = `${GO_TO_MARKET_SUB_ROUTE.BASE}/${GO_TO_MARKET_SUB_ROUTE.GO_TO_MARKET_PRODUCT_GROUPS}`,
    ORGANIZATIONS_CREATE = "/organizations/create",
    PERSONAL_USER_EDIT = "",
    SYSTEM_SUPPORT = "/system-support",
    IMPORT_AND_EXPORT = "/import-and-export",
    ACADEMIC_TEST_BASE = `${MEDICAL_DATABASE_SUB_ROUTE.MEDICAL_DATABASE_SUB_ROUTE_BASE}/${ACADEMIC_TEST_ROUTE}/`,
    ACADEMIC_TEST = `${MEDICAL_DATABASE_SUB_ROUTE.MEDICAL_DATABASE_SUB_ROUTE_BASE}/${MEDICAL_DATABASE_SUB_ROUTE.ACADEMIC_TEST}`,
    ACADEMIC_DISEASE_BASE = `${MEDICAL_DATABASE_SUB_ROUTE.MEDICAL_DATABASE_SUB_ROUTE_BASE}/${ACADEMIC_DISEASE_ROUTE}/`,
    ACADEMIC_DISEASE = `${MEDICAL_DATABASE_SUB_ROUTE.MEDICAL_DATABASE_SUB_ROUTE_BASE}/${MEDICAL_DATABASE_SUB_ROUTE.ACADEMIC_DISEASE}`,
    HEALTHCARE_MEDICAL_DATABASE = `${HEALTHCARE_SUB_ROUTE.BASE}/${HEALTHCARE_SUB_ROUTE.HEALTHCARE_MEDICAL_DATABASE}`,
    CAMPAIGNS = `${CAMPAIGN_SUB_ROUTE.CAMPAIGN_ROUTE_BASE}`,
    CAMPAIGNS_LIST = `${CAMPAIGN_SUB_ROUTE.CAMPAIGN_ROUTE_BASE}/${CAMPAIGN_SUB_ROUTE.CAMPAIGN_LIST}`,
    CAMPAIGN_DETAILS = `${CAMPAIGN_SUB_ROUTE.CAMPAIGN_ROUTE_BASE}/${CAMPAIGN_SUB_ROUTE.CAMPAIGN_DETAILS}`,
    ANALYTICS = "/analytics",
    PARTNERSHIPS = "/partnerships",
    VERIFY_PAYMENT = "/verify-payment",
    LAB_PATIENT_DETAILS = `${LAB_SUB_ROUTE.BASE}/${LAB_SUB_ROUTE.LAB_PATIENTS}/${PATIENT_DETAILS_SUB_ROUTE.PATIENT_DETAILS_ROUTE}`,
    HEALTHCARE_PATIENT_DETAILS = `${HEALTHCARE_SUB_ROUTE.BASE}/${HEALTHCARE_SUB_ROUTE.HEALTHCARE_PATIENT}/${PATIENT_DETAILS_SUB_ROUTE.PATIENT_DETAILS_ROUTE}`,
    LAB_PATIENT_CREATE_REQUISITION = `${LAB_SUB_ROUTE.BASE}/${LAB_SUB_ROUTE.LAB_PATIENTS}/${PATIENT_DETAILS_SUB_ROUTE.PATIENT_CREATE_REQUISITION}`,
    HEALTHCARE_PATIENT_CREATE_REQUISITION = `${HEALTHCARE_SUB_ROUTE.BASE}/${HEALTHCARE_SUB_ROUTE.HEALTHCARE_PATIENT}/${PATIENT_DETAILS_SUB_ROUTE.PATIENT_CREATE_REQUISITION}`,
    TEST_PRODUCT_DETAILS = `${PRODUCT.TEST_PRODUCT_DETAILS_ROUTE}`,
}

export enum AUTHORIZED_SUB_ROUTE {
    AUTHORIZED = "/authorized",
    ERROR_AUTHORIZED = "error",
}

export enum PublicRoutes {
    LOGIN = "/login",
    INIT_AUTH = "/init-auth",
    AUTHORIZED = `${AUTHORIZED_SUB_ROUTE.AUTHORIZED}`,
    FORGOT_PASSWORD = "/forgot-password",
    UPDATE_PASSWORD = "/update-password",
    REGISTER_PARTNER = "/partner-registration",
    // REGISTER_PROCTOR = '/register/proctor', // NG-546
    REGISTER_PATIENT = "/register/patient",
    CONTACT_POINT_VERIFICATION = "/contact-point/verification",
    VERIFY_EMAIL = "/verify-email/:userId/:code",
    REGISTER_HEALTHCARE_PROVIDER = "/healthcare",
    REGISTER_HEALTHCARE_PROVIDER_SUCCESS_PAGE = "/register_healthcare_success",
    LOGIN_PERSONAL_RESULTS_LIST = "/my-orders",
    ERROR_AUTHORIZED = `${AUTHORIZED_SUB_ROUTE.AUTHORIZED}/${AUTHORIZED_SUB_ROUTE.ERROR_AUTHORIZED}`,
    SET_UP_NEW_ORGANIZATION = "/setup-new-organization",
    JOURNEY_ACCESS_VERIFICATION = "/journey/access-verification",
}

export enum StaticRoutes {
    FORBIDDEN = "/forbidden",
    NOT_FOUND = "/not-found",
}

export enum Files {
    JPG = "image/jpeg,image/x-citrix-jpeg,image/pjpeg",
    PNG = "image/png,image/x-citrix-png,image/x-png",
    BMP = "image/bmp",
    ANY_IMG = "image/*",
    ICO = "image/x-icon",
    XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
    PDF = "application/pdf",
    SVG = "image/svg+xml",
    DOC = "application/msword",
    DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    TEXT = "text/plain",
    JSON = "application/json",
}

export enum ImageExtensionTypes {
    PNG = ".png",
    JPG = ".jpg",
    JPEG = ".jpeg",
    SVG = ".svg",
    BMP = ".bmp",
    ICO = ".ico",
    WEBP = ".webp",
    AVF1 = ".avf1",
    JPE = ".jpe",
    PJPG = ".pjpg",
    JFIF = ".jfif",
    JFIFTBNL = ".jfif-tbnl",
    JIF = ".jif",
    JFI = ".jfi",
    GIF = ".gif",
}
export enum ApplicationExtensionTypes {
    PDF = ".pdf",
    XHTML = ".xhtml",
    XLS = ".xls",
    XLSX = ".xlsx",
    XML = ".xml",
    XUL = ".xul",
    EPUB = ".epub",
    DOCX = ".docx",
    ZIP = ".zip",
    SEVEN7Z = ".7z",
    JSON = ".json",
    CSV = ".csv",
    TSV = ".tsv",
}

export enum AudioExtensionTypes {
    WAV = ".wav",
    WEBA = ".weba",
    M2A = ".m2a",
    M3A = ".m3a",
    MP2 = ".mp2",
    MP2A = ".mp2a",
    MP3 = ".mp3",
    MPGA = ".mpga",
}

export enum VideoExtensionTypes {
    WEBM = ".webm",
    MP4 = ".mp4",
    M1V = ".m1v",
    M2V = ".m2v",
    MPA = ".mpa",
    MPE = ".mpe",
    MPG = ".mpg",
}

export enum TextExtensionTypes {
    CONF = ".conf",
    DEF = ".def",
    DIFF = ".diff",
    IN = ".in",
    KSH = ".ksh",
    LIST = ".list",
    LOG = ".log",
    PL = ".pl",
    TEXT = ".text",
    TXT = ".txt",
    SNP = ".snp",
}

export enum MSWordExtensionTypes {
    DOC = ".doc",
    DOT = ".dot",
    WIZ = ".wiz",
}
export enum PowerPointExtensionTypes {
    POT = ".pot",
    PPA = ".ppa",
    PPS = ".pps",
    PPT = ".ppt",
    PWZ = ".pwz",
    PPTX = ".pptx",
}

// ** This type can be used in suitable case for now its not used any where **
export interface CommonSupportedWebFiles {
    "application/pdf": ApplicationExtensionTypes[];
    "application/x-pdf": ApplicationExtensionTypes[];
    "application/x-bzpdf": ApplicationExtensionTypes[];
    "application/x-gzpdf": ApplicationExtensionTypes[];
    "applications/vnd.pdf": ApplicationExtensionTypes[];
    "application/acrobat": ApplicationExtensionTypes[];
    "application/x-google-chrome-pdf": ApplicationExtensionTypes[];
    "text/pdf": ApplicationExtensionTypes[];
    "text/x-pdf": ApplicationExtensionTypes[];
    "application/xhtml+xml": ApplicationExtensionTypes[];
    "application/excel": ApplicationExtensionTypes[];
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ApplicationExtensionTypes[];
    "application/xml": ApplicationExtensionTypes[];
    "application/vnd.mozilla.xul+xml": ApplicationExtensionTypes[];
    "application/epub+zip": ApplicationExtensionTypes[];
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ApplicationExtensionTypes[];
    "application/zip": ApplicationExtensionTypes[];
    "application/x-7z-compressed": ApplicationExtensionTypes[];
    "audio/wav": AudioExtensionTypes[];
    "audio/weba": AudioExtensionTypes[];
    "audio/mpeg": AudioExtensionTypes[];
    "video/webm": VideoExtensionTypes[];
    "video/mp4": VideoExtensionTypes[];
    "video/mpeg": VideoExtensionTypes[];
    "text/plain": TextExtensionTypes[];
    "application/msword": MSWordExtensionTypes[];
    "application/vnd.ms-powerpoint": PowerPointExtensionTypes[];
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": PowerPointExtensionTypes[];
    "image/jpeg": ImageExtensionTypes[];
    "image/x-citrix-jpeg": ImageExtensionTypes[];
    "image/pjpeg": ImageExtensionTypes[];
    "image/png": ImageExtensionTypes[];
    "image/x-citrix-png": ImageExtensionTypes[];
    "image/bmp": ImageExtensionTypes[];
    "image/x-icon": ImageExtensionTypes[];
}

export interface AcceptFormat {
    [x: string]: ImageExtensionTypes[];
}

export enum UserRoles {
    Patient = "Patient", // Every user is a patient, valid also for Default tenant
    // Doctor = 'Doctor',
    Staff = "Staff",
    Proctor = "Proctor",
    // MTA = 'MTA',
    Employee = "Employee",
    Manager = "Manager",
    SA = "System Admin", // 'System Administrator'
    CS = "Customer Support", // 'Customer Support'
}

export enum OrganizationRole {
    LAB = "Lab",
    GO_TO_MARKET = "Go To Market",
    SUPPLIER = "Supplier",
    HEALTH_PROVIDER = "Health Provider",
    POPULATION_HEALTH = "Population Health",
}

export const OrganizationRoleRouteMap = {
    [OrganizationRole.LAB]: "lab",
    [OrganizationRole.HEALTH_PROVIDER]: "healthcare",
};

export const RouteOrganizationRoleMap = {
    lab: OrganizationRole.LAB,
    healthcare: OrganizationRole.HEALTH_PROVIDER,
};

export enum CreateOrganizationKey {
    LAB_SUPPLIER_GTM = "lab-supplier-gtm",
    HEALTH_CARE = "healthcare",
}

export enum TenantCapabilities {
    PROCTORING_SERVICE = "Proctoring",
    CROSS_TENANT_ANALYTICS = "Cross-Tenant Analytics",
    STRIPE_INTEGRATION = "Stripe Integration",
    GENETIC_TRAILS_AND_ANCESTRY = "Genetic Traits and Ancestry",
    DRY_TEST_PROBABILITY_CALCULATION = "Dry Test Probability Calculation",
}

export enum InputTypes {
    SELECT,
    TEXT,
    DATE,
    CHECKBOX,
}

export enum GoogleAddressCountries {
    UNITED_STATES_OF_AMERICA = "United States",
    CANADA = "Canada",
    AUSTRALIA = "Australia",
}

export enum AppAddressCountries {
    UNITED_STATES_OF_AMERICA = "United States",
    CANADA = "Canada",
    AUSTRALIA = "Australia",
}

export enum OrganizationContactTypes {
    PRIMARY = "Primary",
    FINANCE = "Finance",
    OPERATIONS_AND_SUPPORT = "Operations and Support",
    TECHNICAL = "Technical",
}

export enum ProfileTab {
    CONTACTS = 0,
    INSURANCE = 1,
    MEDICAL = 2,
}

export enum TestOfferedTab {
    TEST_OFFERED = 0,
    ICD10_CODES = 1,
    COLLECTION_SPECIFICATIONS = 2,
    ASK_AT_ORDER_ENTRY = 3,
    DETAILED_DESCRIPTIONS = 4,
    RESULTS = 5,
    PRICING_CONFIG = 6,
    MARKETING = 7,
}

export enum TestProductsTab {
    PRODUCT_COMPONENT = 0,
    PRODUCT_JOURNEY = 1,
    AVAILABILITY = 2,
    RESULT_REPORT = 3,
    PRICING_AND_CONFIGURATION = 4,
    MARKETING = 5,
}

export enum SamplingKitTab {
    SAMPLING_KIT_MAIN = 0,
}

export enum TestProductTab {
    PRODUCT_COMPONENTS = 0,
}

export enum SupplyCatalogTab {
    SUPPLY_PRODUCTS = 0,
    BILL_OF_MATERIALS = 1,
}

export enum SupplyProductTab {
    SUPPLY_PRODUCT = 0,
    BILL_OFF_MATERIALS = 1,
}

export enum AccountTab {
    PERSONAL_USER = 0,
}

export enum HTMLTemplateTypes {
    PUBLISHED = "published",
    WORKING = "working",
    COMPILED = "compiled",
    SET_DEFAULT = "setDefault",
}

export enum AccountSettingsTabs {
    PERSONAL_ACCOUNT,
    INDIVIDUAL_PROVIDER_REGISTRATION,
    MY_ORGANIZATIONS,
}
export enum PersonalAccountSettingsTabs {
    PERSONAL_ACCOUNT,
}

export enum HealthCareOrderTabs {
    ORDERS_PLACED,
    ORDERS_PRESCRIBED,
    RESULTED,
    ORDERS_TO_DO,
    OUR_STEPS_COMPLETED,
}

export enum MyTestsTabs {
    ACTIVE_ORDERS,
    ALL_ORDERS,
}

export enum GoToMarketOrderTabs {
    ORDERS_PLACED,
    ORDERS_PRESCRIBED,
    ORDERS_TO_DO,
    OUR_STEPS_COMPLETED,
}

export enum OrderOutcome {
    IN_PROGRESS = "In Progress",
    CANCELLED = "Cancelled",
    REJECTED = "Rejected",
    COMPLETED = "Completed",
}

export enum WorkflowStepStatus {
    IN_PROGRESS = "In Progress",
    CANCELLED = "Cancelled",
    REJECTED = "Rejected",
    COMPLETED = "Completed",
    ON_HOLD = "On Hold",
    TOTAL = "Total",
}

export enum TargetAction {
    Default = "default",
    Next = "next",
    SaveAndExit = "saveAndExit",
    Discard = "discard",
    SaveAndStay = "saveAndStay",
    DiscardAndStay = "discardAndStay",
    OldFormErrorAndStay = "oldFormErrorAndStay",
    ExitScreen = "exitScreen",
}

export enum navigateAction {
    NEXT = "next",
    EXIT = "exit",
    INPLACE = "inplace",
    BLOCKER = "blocker",
}

export enum MedicalRecordsTabs {
    LAB_RESULTS,
    VACCINATION,
    ALL_DOCUMENTS,
}
export enum PatientDataTabs {
    PROFILE,
    TEST_ORDERS,
    LAB_RESULTS,
    INSURANCE,
    MEDICATIONS,
    VACCINATIONS,
    ENCOUNTERS,
    DOCUMENTS,
}

export enum WorkflowTemplateCategory {
    GENERAL = "general",
    GENETICS = "genetics",
    RESEARCH = "research",
    MY_WORKFLOW = "my-workflow",
    BLANK = "blank",
    AI_BUILDER = "ai-builder",
}

export enum IntegrationsSystemLogoTypes {
    SYSTEM_LOGO = "system-logo",
}

export enum WorkflowType {
    ALL = "All",
    CAMPAIGN = "Campaign",
    ORDER = "Order",
}

export type CardLogoTypes = WorkflowTemplateCategory | IntegrationsSystemLogoTypes | WorkflowType;

export enum TEST_RESULT_TYPES {
    ORDER_TYPE = "Order",
    TEST_RESULT_TYPE = "Old Result - Data File",
    OLD_COMPLETE_RESULT_TYPE = "Old Result - DNA Traits",
    OLD_QUALITATIVE_RESULT_TYPE = "Old Result - Qualitative",
    OLD_QUANTITATIVE_RESULT_TYPE = "Old Result - Quantitative",
    OLD_INCOMPLETE_RESULT_TYPE = "Old Result - DNA Traits Incomplete",
}

export enum LOGIN_TYPE {
    GOOGLE = "google-idp",
    MICROSOFT = "azure-dev",
}

export enum FlyInActionTypes {
    FILE_UPLOAD = "fileUpload",
    SEND_NOTIFICATION = "sendNotification",
    ASSIGN_TO_PERSON = "assignToPerson",
    GENERETA_QR_CODE = "generateQRCode",
    SHOW_WARNING = "showWarning",
}

export enum TEST_RESULT_DEFINITIONS_TYPES {
    QUANTITATIVE = "Quantitative",
    QUALITATIVE = "Qualitative",
    DATA_FILE = "Data File",
    PDF_OR_IMAGE = "PDF or Image",
}

export enum TEST_RESULT_DATE_MODIFIER_OPTIONS {
    NO_VALIDATION = "No Validation",
    EXACT_DATE = "Exact Date",
    FUTURE_DATE = "Future Date",
    TODAY_OR_FUTURE_DATE = "Today Or Future Date",
    PAST_DATE = "Past Date",
    TODAY_OR_PAST_DATE = "Today Or Past Date",
}

export enum PAYMENT_TYPE {
    CASH = "Cash",
    INSURANCE = "Insurance",
    INVOICE = "Invoice",
}

export enum ContactTypes {
    PRIMARY = "Primary",
    HOME = "Home",
    MAIN = "Main",
    OLD = "Old",
    PERSONAL = "Personal",
    MAILING_LIST = "Mailing List",
    WORK = "Work",
    MAIN_WORK = "Main Work",
    OTHER_WORK = "Other Work",
    VACATION_HOME = "Vacation Home",
}

export enum QualitativeResultValues {
    ONE_PLUS = "1+",
    TWO_PLUS = "2+",
    THREE_PLUS = "3+",
    POSITIVE = "positive",
    NEGATIVE = "negative",
    INDETERMINATE = "indeterminate",
    REACTIVE = "reactive",
    NONREACTIVE = "nonreactive",
    HIGH = "high",
    LOW = "low",
    MODERATE = "moderate",
    NORMAL = "normal",
    ABNORMAL = "abnormal",
    DETECTED = "detected",
    NOT_DETECTED = "not detected",
    SEE_NOTE = "see note",
    NOT_PERFORMED = "not performed",
    NOT_REPORTED = "not reported",
}

export enum InsuranceStatus {
    ACTIVE = "Active",
    DELETED = "Deleted",
}

export enum TrendType {
    UPWARD = "upward",
    DOWNWARD = "downward",
    STABLE = "stable",
    // FLUCTUATING = "fluctuating",
    // UNDEFINED = "undefined",
}

export enum CampaignStatus {
    PREPARED = "Prepared",
    INITIALIZING = "Initializing",
    IN_PROGRESS = "In Progress",
    CANCELLED = "Canceled",
    FINISHED = "Finished",
}

export enum PartnershipStatus {
    APPROVED = "Approved",
    REJECTED = "Rejected",
    PENDING = "Pending",
}

export enum PartnershipDirection {
    OUTBOUND = "Outbound",
    INBOUND = "Inbound",
}

export enum LabelTagType {
    IMPORT = "Import",
    JOURNEY = "Journey",
}

export enum LabelTagOwnership {
    All = "all",
    SHARED = "shared",
    OWN = "own",
}
